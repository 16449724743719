function PrepareObject (data) {
    const item = data.data;
    const result = {
        id: item.id,
        name: item.attributes.object_name,
    }

    return result;

}

export default PrepareObject;
