import React, { useState, useEffect } from "react";
import styles from "./popup.module.scss";

export const PopupForm = ({ item }) => {

  const [formValue, updateFormValue] = useState({
    clientName: "",
    clientPhone: "",
    partnerName: "",
    partnerEmail: "",
    partnerPhone: "",
    policyChecked: "",
    object: item != null ? item.name : "Не выбран",
  });

  const [errorList, updateErrorList] = useState([]);

  const [formState, updateFormState] = useState("isDisplayed");

  function contains(arr, elem) {
    if(arr.length == 0) {
      return false;
    }
    else {
      return arr.indexOf(elem) != -1;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    function validateForm() {
      let errorCounter = 0;
      let localErrorList = [];
      if (formValue.partnerPhone == "") {
        errorCounter++;
        localErrorList.push('partnerPhoneIsEmpty');
      }
      if (formValue.policyChecked == false) {
        errorCounter++;
        localErrorList.push('policyCheckboxIsNotChecked');
      }
      updateErrorList(localErrorList);
      
      if (errorCounter == 0) {
        return true;
      }
      return false;
    }

    if (validateForm()) {
      fetch("https://estate.ipoteka.center/sendBotMessageOverseas.php", {
        method: "POST",
        mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValue),
      }).then((response) => {
        if (response.status !== 200) {
        }
      });
      updateFormState("isSent");
    } else {
      updateFormState("partnerPhoneIsEmpty"); 
    }
  }

  function handleInputChange(event) {
    updateFormValue(getUpdatedForm(event.target.name, event.target.value));
  }

  function handleCheckboxChange(event) {
    var checked = event.target.checked;
    updateFormValue(getUpdatedForm('policyChecked', checked));
  }
    

  function getUpdatedForm(name, value) {
    const queryString = window.location.search;
    let currentformValue = formValue;
    let itemName = item != null ? item.name : "Не выбран";
    let developerName = item != null ? item.company : "Не выбран";
    currentformValue = {
      ...currentformValue,
      object: itemName,
      developer: developerName,
      [name]: value,
      utm: queryString
    };

    return currentformValue;
  }

  let phoneClasses = function() {
    if(contains(errorList, 'partnerPhoneIsEmpty')) {
      return styles.input_white + ' ' + styles.input_error;
    }
    else {
      return styles.input_white;
    }
  }

  let checkboxClasses = function() {
    if(contains(errorList, 'policyCheckboxIsNotChecked')) {
      return styles.checkboxContainer + ' ' + styles.checkboxContainer_error;
    }
    else {
      return styles.checkboxContainer;
    }
  }

  function renderForm(state) {
    switch (state) {
      case "isSent":
        return (
          <div className={styles.form_isSent}>
            <div className={styles.formSuccessImg}></div>
            <div
              className={
                styles.form__heading + " " + styles.form__heading_center
              }
            >
              Спасибо за заявку
            </div>
            <div
              className={
                styles.form__subheading + " " + styles.form__subheading_center
              }
            >
              Мы скоро свяжемся с вами, расскажем детали об объекте и ответим на
              все ваши вопросы.
            </div>
          </div>
        );
        break;
      default:
        return (
          <div className={styles.form_isDisplayed}>
            <div className={styles.form__heading}>
              Подробная консультация от&nbsp;нашего&nbsp;менеджера
            </div>
            <div className={styles.form__subheading}>
              Расскажем об&nbsp;объекте и&nbsp;отправим
              все&nbsp;презентационные материалы
            </div>
            <form
              action=""
              className={styles.verticalForm}
              onSubmit={(event) => handleSubmit(event)}
            >
              <input
                placeholder="Ваше имя"
                className={styles.input_white}
                type="text"
                name="partnerName"
                onChange={handleInputChange}
              />
              <input
                placeholder="Ваш телефон"
                className={phoneClasses()}
                type="text"
                name="partnerPhone"
                onChange={handleInputChange}
              />
              <input
                placeholder="Ваша почта"
                className={styles.input_white}
                type="text"
                name="partnerEmail"
                onChange={handleInputChange}
              />
              <input
                placeholder="ФИО клиента"
                className={styles.input_white}
                type="text"
                name="clientName"
                onChange={handleInputChange}
              />
              <input
                placeholder="Телефон клиента"
                className={styles.input_white}
                type="text"
                name="clientPhone"
                onChange={handleInputChange}
              />
             <label className={checkboxClasses()}> Даю согласие на обработку и передачу <a href="https://drive.google.com/file/d/1CmDsAzWkmZsPCDIaXPFH4xTCPq3HLeqN/view?usp=share_link" target="_blank" className={styles.link}>персональных данных</a>
                <input type="checkbox" onChange={handleCheckboxChange} name='policyChecked'/>
                <span className={styles.checkmark}></span>
              </label>
              <input
                className={styles.button}
                type="submit"
                value="Получить консультацию"
              />
            </form>
          </div>
        );
        break;
    }
  }

  return renderForm(formState);
};
