import {React, useState, useEffect, useRef} from "react";
import EstateItems from "components/estate/EstateItems";
import Tabs from "components/tabs/Tabs";
import Faq from "components/FAQ/Faq";
import styles from "./content.module.scss";
import Button from "../../library/UI/Button";
import Benefits from "components/benefits/Benefits";
import Filters from "../filters/Filters.jsx";

function Content({ handler }) {
  const [isFilterVisible, toggleFilterVisibility] = useState(false);

  let toggleFilterHandler = () => {
    toggleFilterVisibility(!isFilterVisible);
  }; 

  const [currentFilters, setCurrentFilters] = useState({
    developer: {
      value: 0,
      label: "Все",
    },
    foundation: {
      value: 0,
      label: "Все",
    },
    wall: {
      value: 0,
      label: "Все",
    },
    regions: {}
  });

  function changeFilters(value) {
    setCurrentFilters(value)
    console.log(value);
  }



  let pageHeader, subHeadingFirstline;

  return (
    <div className={styles.contentContainer}>
      <div className={styles.mainContainer}>
        <div className={styles.pageHeader}>
          <h1 className="heading heading_M">
            Объекты ИЖС
          </h1>
          <a href="https://docs.google.com/spreadsheets/d/1yKxeT__jtDZwakPY_7vbgpD8lrpADZtLAcsGlLAq4TM/edit?usp=sharing" target="_blank" className="link text_L">
            Подрядчики и бонусы
          </a>
        </div>
        
        <a name="objects"></a>
        <EstateItems handler={handler} currentFilters={currentFilters} />
        <Benefits />
        <Faq />
        <div className={styles.footer}>
          <a className={styles.footerLink} target="_blank" href="https://legal.ipoteka.center/privacy-policy">Политика конфиденциальности</a>
        </div>
      </div>
      <Filters setCurrentFilters={changeFilters} isVisible={isFilterVisible}></Filters>
      <div className={styles.button} onClick={toggleFilterHandler}>{!isFilterVisible ? 'Показать фильтры' : 'Применить фильтры'}</div>
    </div>
  );
}

export default Content;
