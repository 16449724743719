import React, { useState } from "react";
import MobileItem from "./MobileItem";
import styles from "./mobilemenu.module.scss";
import { ReactComponent as HamburgerIcon } from "./images/hamburger.svg";
import { ReactComponent as Logo } from "./images/logo.svg";
import { ReactComponent as CloseIcon } from "./images/close.svg";

function MobileMenu({ items }) {
  const [isOpened, setIsOpened] = useState(false);

  const toggleMenu = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.header}>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <HamburgerIcon />
        </div>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.headerWrapper}></div>
      </div>
      <div
        className={styles.container + " " + (isOpened && styles.container_show)}
      >
        <CloseIcon
          onClick={toggleMenu}
          width="20"
          height="20"
          className={styles.closeIcon}
        />
        {items.map((mobileBlock, mobileBlockIndex) => (
          <div className={styles.mobileBlock}>
            {mobileBlock.map((mobileMenuItem, mobileItemIndex) => (
              <MobileItem item={mobileMenuItem} key={mobileItemIndex} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MobileMenu;
