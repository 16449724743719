import React, {useState}  from 'react';
import styles from './faq.module.scss';
import {ReactComponent as DropdownIcon} from './images/dropdown.svg';

function FaqItem({item}) {
  const [isOpened, setIsOpened] = useState( false );

  const toggleItem = () => {
    setIsOpened(!isOpened);
  }

  return (
    <div className={styles.item + " " + (isOpened && styles.item_opened)}>
      <DropdownIcon className={styles.icon}  onClick={toggleItem}/>  
      <div className={styles.question} onClick={toggleItem}> 
        {item.q}
      </div>
      <div className={styles.answer}> 
        {item.a}
      </div>
      
    </div>
  );
}

export default FaqItem;
