import { configureStore } from "@reduxjs/toolkit";
import { objectsReducer, objectReducer, filterReducer, popupReducer } from "./objectSlice";

export default configureStore({
  reducer: {
    objects: objectsReducer,
    object: objectReducer,
    filters: filterReducer,
    popup: popupReducer,
  },
});
