import React from 'react';
import styles from './mobilemenu.module.scss';

function MobileItem({item}) {

  return (
    <a href={item.url} className={styles.mobileItem}>
        <img className={styles.mobileItem__img} src={"/img/menuIcons/" + item.img} alt="" />
        <div className={styles.mobileItem__name + " " + (( item.activity || false ) && styles.mobileItem__name_active)}>
            {item.name}
        </div>
    </a>
  );
}

export default MobileItem;
