import styles from "./popup.module.scss";
import { ReactComponent as CloseIcon } from "./images/close.svg";

import { useSelector, useDispatch } from "react-redux";
import { hidePopup } from "store/objectSlice";
import { PopupForm } from "./PopupForm";
import WordDeclination from "helpers/WordDeclination";
import FormatNumber from "helpers/FormatNumber";



function PopupAbout() {
  const dispatch = useDispatch();
  const popupState = useSelector((state) => state.popup.status);
  const popupItem = useSelector((state) => state.popup.item);

  const hideCurrentPopup = () => {
    dispatch(hidePopup());
  };

  const bgStyle = {
    backgroundImage: popupItem
      ? `url(https://test.2106249-cs44578.twc1.net${popupItem.photo})`
      : "none",
    backgroundSize: "cover",
  };

  let popupDescription = [
      [
        popupItem ? popupItem.area + " м²" : "",
        "•",
        popupItem ? popupItem.floors + " " + WordDeclination(popupItem.floors, 'этаж', 'этажа','этажей') : "",
        "•",
        popupItem ? popupItem.bedroom_number + " " + WordDeclination(popupItem.bedroom_number, 'спальня', 'спальни','спален') : "",
        "•",
        popupItem ? popupItem.bathroom_number + " " + WordDeclination(popupItem.bathroom_number, 'ванная', 'ванные','ванных') : "",
      ],
      [
        popupItem ? popupItem.foundation + " фундамент" : "",
        "•",
        popupItem ? popupItem.wall_material  : "",
      ],
    ];

  function getDescriptionHTML() {
    let html;

    
    return (
      <div className={styles.description__items}>
        {
          popupDescription.map((line, index) => {
            return (
              <div className={styles.description__line}>
                {
                  line.map((item, itemIndex) => {
                    return (<div className={styles.description__item}>{item}</div>)
                  })
                }
              </div>
              )
            })
        }
        </div>
    );
  } 


  return (
    <div
      className={styles.container + " " + (popupState && styles.container_show)}
    >
      <div className={styles.window}>
        <div className={styles.closeIcon} onClick={hideCurrentPopup}>
          <CloseIcon />
        </div>
        <div className={styles.aboutBlock}>
          <div style={bgStyle} className={styles.photo}></div>
          
          <div className={styles.descriptionBlock}>
            <div className={styles.company}>
              {popupItem !== null ? popupItem.company : ""}
            </div>
            <div className={styles.name}>
              {popupItem !== null ? popupItem.name : ""}
            </div>
            <div className={styles.price}>
              от {popupItem? FormatNumber(popupItem.price) : ""} руб.
            </div>
            <div className={styles.additionalInfo}>
              {getDescriptionHTML()}
              <div className={styles.additionalText}>Возможно строительство по индивидуальному проекту</div>
            </div>
            <div className={styles.text}>
              {popupItem !== null ? popupItem.description : ""}
            </div>
          </div>
        </div>
        <div className={styles.formBlock}>
          <PopupForm item={popupItem} />
        </div>
      </div>
    </div>
  );
}

export default PopupAbout;
