export const formatFilters = (filters) => {
    const queryParams = new URLSearchParams();
  
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if(value.value != 0) {
        if (key === 'developer') {
            queryParams.append(`filters[house_developer][developer_name][$eq]`, value.label);
        } else if (key === 'wall') {
            queryParams.append(`filters[wall_material][Type][$eq]`, value.label);
        } else if (key === 'regions') {
            if(value.length > 0) {
                value.forEach(regionItem => {
                    queryParams.append(`filters[regions][region_name][$in]`, regionItem.label);
                })
            }
        } else if (key === 'price') {
            let from = 0;
            let to = 1000;
            let million = 1000000;
            if (value.from !== null && value.from !== 0 && value.from !== '') {
                from = value.from;
            }
            if(value.to !== null && value.to !== 0 && value.to !== '') {
                to = value.to;
            }
            queryParams.append(`filters[price][$between]`, from * million);
            queryParams.append(`filters[price][$between]`, to * million);
        } else if (key === 'area') {
            let from = 0;
            let to = 1000;
            if (value.from !== null && value.from !== 0 && value.from !== '') {
                from = value.from;
            }
            if(value.to !== null && value.to !== 0 && value.to !== '') {
                to = value.to;
            }
            queryParams.append(`filters[area][$between]`, from);
            queryParams.append(`filters[area][$between]`, to);
        } else if (key === 'term') {
            console.log(value.from);
            let dayInMonth = 30;
            if (value.to !== 0) {
                queryParams.append(`filters[construction_period][$between]`, value.from * dayInMonth);
                queryParams.append(`filters[construction_period][$between]`, value.to * dayInMonth);
            }
        }
      }
      console.log(queryParams.toString());

    });
  
    return queryParams.toString();
  };