import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import objectsApi from "services/objectsApi";
import objectApi from "services/objectApi";
import filtersApi from "services/filtersApi";

export const fetchObjects = createAsyncThunk(
  "todos/fetchObjects",
  async function ({page, filters}, { rejectWithValue }) {
    try {
      const objects = await objectsApi(page, filters);

      return {objects, page};
    } catch (error) {}
  }
);

export const fetchObject = createAsyncThunk(
  "todos/fetchObject",
  async function (id, { rejectWithValue }) {
    try {
      const object = await objectApi(id);
      return object;
    } catch (error) {}
  }
);

export const fetchFilters = createAsyncThunk(
  "todos/fetchFilters",
  async function (country, { rejectWithValue }) {
    try {
      const objects = await filtersApi();
      return objects;
    } catch (error) {}
  }
);

const objectsSlice = createSlice({
  name: "objects",
  initialState: {
    items: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchObjects.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchObjects.fulfilled]: (state, action) => {
      state.status = "resolved";
      if (action.payload.page === 1) {
        state.items = action.payload.objects;
      } else {
        state.items = [...state.items, ...action.payload.objects];
      }
      state.page = action.payload.page;
      state.error = null;
    },
    [fetchObjects.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

const objectSlice = createSlice({
  name: "object",
  initialState: {
    items: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchObject.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchObject.fulfilled]: (state, action) => {
      state.status = "resolved";
      state.items = action.payload;
      state.error = null;
    },
    [fetchObject.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    items: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchFilters.pending]: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    [fetchFilters.fulfilled]: (state, action) => {
      state.status = "resolved";
      state.items = action.payload;
      state.error = null;
    },
    [fetchFilters.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

const popupSlice = createSlice({
  name: "popup",
  initialState: {
    status: false,
    item: null,
  },
  reducers: {
    showPopup(state, action) {
      state.status = true;
      state.item = action.payload.item;
    },
    hidePopup(state, action) {
      state.status = false;
    },
  },
});

export const { showPopup, hidePopup } = popupSlice.actions;

export const objectsReducer = objectsSlice.reducer;
export const objectReducer = objectSlice.reducer;
export const filterReducer = filterSlice.reducer;

export const popupReducer = popupSlice.reducer;
