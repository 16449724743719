import PrepareData from "helpers/PrepareData";
import { formatFilters } from "helpers/formatFilters";

const objectsApi = async (page = 1, filters = null) => {
  const fetchObjects = await fetch(
    `https://test.2106249-cs44578.twc1.net/api/houses?populate=*&pagination[page]=${page}&pagination[pageSize]=10&${formatFilters(filters)}`
  );
  const data = await fetchObjects.json();
  const preparedObjects = PrepareData(data);
  return preparedObjects;
};

export default objectsApi;
