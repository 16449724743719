import React from "react";
import styles from "./tabs.module.scss";
import { ReactComponent as TurkeyFlag } from "library/icons/turkey-flag.svg";
import { ReactComponent as EmiratesFlag } from "library/icons/emirates-flag.svg";
import { Link } from "react-router-dom";

function Tab({ current, test }) {
  const items = [
    {
      name: "Emirates",
      text: "ОАЭ и Дубаи",
      link: "/emirates",
      icon: <EmiratesFlag width="30px" height="30px" />,
    },
    {
      name: "Turkey",
      text: "Турция",
      link: "/turkey",
      icon: <TurkeyFlag width="30px" height="30px" />,
    },
  ];

  return (
    <div className={styles.tabs}>
      {items.map((item) => (
        <Link
          className={
            current === item.name
              ? styles.item_active + " " + styles.item
              : styles.item
          }
          to={item.link}
        >
          {item.icon}
          {item.text}
        </Link>
      ))}
    </div>
  );
}

export default Tab;
