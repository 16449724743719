import PrepareObject from "helpers/PrepareObject";

const objectApi = async (id = 1) => {
  const fetchObject = await fetch(
    `https://test.2106249-cs44578.twc1.net/api/houses/${id}?populate=*`
  );

  const data = await fetchObject.json();
  const preparedObject = PrepareObject(data);

  return preparedObject;
};

export default objectApi;
