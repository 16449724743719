import React from 'react';
import styles from './menu.module.scss';



function MenuItem({type, item}) {

  return (
    <a href={item.url} className={styles.menuItem + " " + (( item.activity || false ) && styles.menuItem_active)}>
        <img src={"/img/menuIcons/" + item.img} alt="" />
        { type === 'top' && <div className={styles.menuItem__name}> {item.name} </div>}
    </a>
  );
}

export default MenuItem;
