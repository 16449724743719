import React, { useEffect } from "react";
import Content from "../components/content/Content";

function Main() {
  return (
    <div>
      <Content />
    </div>
  );
}
export default Main;
