import React, { useState, useEffect } from "react";
import Main from "pages/Main";
import Menu from "components/menu/Menu";
import PopupAbout from "components/popup/PopupAbout";
import PrepareData from "helpers/PrepareData";
import "library/library.scss";
import "App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [popupState, setPopupState] = useState(false);
  const [popupItem, setPopupItem] = useState(null);

  const openPopup = (item) => {
    setPopupState(true);
    setPopupItem(item);
  };

  const hidePopup = () => {
    setPopupState(false);
  };

  return (
    <div className="App">
      <Menu />
      <div className="contentWrapper">
        <Router>
          <Routes>
            <Route
              element={<Main openPopup={openPopup} />}
              path="/"
            />
          </Routes>
        </Router>
      </div>
      <PopupAbout />
    </div>
  );
}

export default App;
