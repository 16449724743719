import React  from 'react';
import styles from './button.module.scss';

function Button({text, type = 'link', link = '#', size = 'm'}) {
        
  return (
    <a href={link} className={styles.button_m}>
        {text}
    </a>
  );
}

export default Button;
