import React, { useState, useEffect } from 'react';
import styles from './rangeinput.module.scss';
import useDebounce from 'hooks/useDebounce';

function RangeInput({label, name, value, onChange, type = 'number'}) {
  const [priceRange, setPriceRange] = useState({ from: null, to: null });
  const debouncedPriceRange = useDebounce(priceRange, 500);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPriceRange(prevRange => ({
      ...prevRange,
      [name]: value
    }));
  };

  useEffect(() => {
    if(priceRange.from != null || priceRange.to != null) {
      onChange(debouncedPriceRange, name);
    }
  }, [debouncedPriceRange]);
        
  return (
  <div className={styles.inputGroup}>
    <div className={styles.label}>
      {label}
    </div>
    <div className={styles.rangeInput}>
        
        <div className={styles.inputContainer}>
          <span className={styles.inputLabel}>
            От
          </span>
          <span className={styles.inputDimension}>
            {type == 'million' ? 'млн' : ''}
          </span>
          <input className={styles.input} type="number" name="from" onChange={handleInputChange}/>
        </div>
        <div className={styles.inputContainer}>
          <span className={styles.inputLabel}>
            До
          </span>
          <input className={styles.input} type="number" name="to" onChange={handleInputChange}/>
          <span className={styles.inputDimension}>
            {type == 'million' ? 'млн' : ''}
          </span>
        </div>
      </div>
  </div>
   
   
  );
}

export default RangeInput;
