import { React, useState, useEffect, useRef } from "react";
import EstateItem from "./EstateItem.jsx";
import { useSelector, useDispatch } from "react-redux";
import { fetchObjects, fetchFilters } from "store/objectSlice";
import debounce from "../../helpers/Debounce.jsx";
import styles from "./estateItems.module.scss";
import { ReactComponent as FilterError } from "./images/icon_empty-filter.svg";

function EstateItems({ handler, currentFilters }) {
  

  const {items} = useSelector((state) => {
   return state.objects});
  const listEndRef = useRef(); 
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);


  useEffect(() => {
    setPage(1);
    dispatch(fetchObjects({page: 1, filters: currentFilters}));
  }, [dispatch, currentFilters]);

  const increasePage = () => {
    setPage(prevCounter => prevCounter + 1);
  };  

  

  let i = 0;

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!listEndRef.current) return;
      const { bottom } = listEndRef.current.getBoundingClientRect();
      const isVisible = bottom <= window.innerHeight;

      if (isVisible) {
        increasePage();
        dispatch(fetchObjects({page: page + 1, filters: currentFilters}));
      }
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [dispatch, page, currentFilters]);

  function showItems(objects) {
    if (objects.length > 0) {
      return (
        <div className={styles.objects}>
          {objects.map((item, index) => (
            <EstateItem item={item} key={index} handler={handler} />
          ))}
          <div ref={listEndRef}></div>
        </div>
      );
    } else {
      return (
        <div className={styles.filterError}>
          <FilterError />
          <div className={styles.filterError__header}>
            Пока нет объектов,соответствующих фильтрам
          </div>
          <div className={styles.filterError__text}>
            Уже подключаем новых подрядчиков  <br />и будем рады рекомендациям: <br />
          </div>
          <div className={styles.filterError__text}>
            {/* Пишите Александру Страхову: <br /> */}
            <a className={styles.filterError__link} href="mailto:o.bogdanova@ipoteka.center">o.bogdanova@ipoteka.center</a>  <a className={styles.filterError__link}  href="https://t.me/volga_bogdanova">Телеграм</a>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={styles.estateItems}>
      
      {showItems(items)}
     
    </div>
  );
}

export default EstateItems;
