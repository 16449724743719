import { React, useState, useEffect } from "react";
import styles from "./filters.module.scss";
import Select from "react-select";
import { fetchObjects, fetchFilters } from "store/objectSlice";
import { useSelector, useDispatch } from "react-redux";
import RangeInput from "library/UI/inputs/RangeInput";

const fondationOptions = [
  { value: 0, label: "Все типы фундаментов" },
  { value: "1", label: "Ленточный" },
  { value: "2", label: "Свайный" },
];

const regionOptions = [
  { value: "1", label: "Москва и МО" },
  { value: "2", label: "Санкт-Петербург и ЛО" },
  { value: "3", label: "Краснодар" },
  { value: "4", label: "Ижевск" },
  { value: "5", label: "Новосибирск" },
  { value: "6", label: "Калининград" },
  { value: "7", label: "Пермь" },
  { value: "8", label: "Казань" },
];

const termOptions = [
  { value: "0", label: "Любой срок постройки", from: 0, to: 0},
  { value: "2", label: "до 2 мес", from: 0, to: 2 },
  { value: "6", label: "от 2 до 6 мес", from: 2, to: 6 },
  { value: "12", label: "от 6 мес до года", from: 6, to: 12 },
  { value: "64", label: "больше года", from: 12, to: 64 },
];



function Filters({ country, setCurrentFilters, isVisible }) {
  const dispatch = useDispatch();

  console.log(isVisible); 
  const [stateFilters, setFiltersState] = useState(
    {
      developer: {
        value: 0,
        label: "Все застройщики",

      },
      fondation: {
        value: 0,
        label: "Все типы фундамента",

      },
      wall: {
        value: 0,
        label: "Все типы стен",

      },
      regions: {
        value: 0,
        label: "Все регионы",
      },
      price: {
        from: 0,
        to: 0
      },
      area: {
        from: 0,
        to: 0
      }
  });

  const [isVisibleOnMobile, toggleVisibilityOnMobile] = useState(false);

  function onUpdateFilters(filtersValue) {
    setCurrentFilters(filtersValue);
  }

  useEffect(() => {
    dispatch(fetchFilters());
  }, [dispatch]);

  const filterValues = useSelector((state) => state.filters.items);

  let onChangePrice = (value, name) => {
    setStatePrice(value);
    onUpdateFilters({ foundation: stateFoundation, developer: stateDeveloper, wall: stateWall, regions: stateRegions, price: value, area: stateArea });
  }

  let onChangeArea = (value, name) => {
    setStateArea(value);
    onUpdateFilters({ foundation: stateFoundation, developer: stateDeveloper, wall: stateWall, regions: stateRegions, price: statePrice, area: value });
  }

  

  const [stateDeveloper, setStateDeveloper] = useState({
    value: 0,
    label: "Все застройщики",
  });
  const [statePrice, setStatePrice] = useState({
    from: 0, to: 0
  });
  const [stateArea, setStateArea] = useState({
    from: 0, to: 0
  });
  const [stateFoundation, setStateFoundation] = useState({
    value: 0,
    label: "Все типы фундамента",
  });
  const [stateWall, setStateWall] = useState({
    value: 0,
    label: "Все типы фундамента",
  });

  const [stateTerm, setStateTerm] = useState({
    value: 0,
    label: "Любой срок постройки",
  });

  const [stateRegions, setStateRegions] = useState({});

  // const optionsDevelopers = allDevelopers.filter(
  //   (item) => item.country === country || item.label === "Все застройщики"
  // );

  let filtersValue = {
    foundation: stateFoundation,
    developer: stateDeveloper,
    wall: stateWall,
    regions: stateRegions
  };

  function setFilters(filterName, value) {
    filtersValue.filterName = value;
  }

  let handleChangeDeveloper = (selectedOption) => {
    console.log(selectedOption);
    setStateDeveloper(
      selectedOption,
    );
    onUpdateFilters({ foundation: stateFoundation, developer: selectedOption, wall: stateWall, regions: stateRegions, area: stateArea, price: statePrice });
  };

  let handleChangeFoundation = (selectedOption) => {
    setStateFoundation(
      selectedOption
    );
    onUpdateFilters({ foundation: selectedOption, developer: stateDeveloper, wall: stateWall, regions: stateRegions, area: stateArea, price: statePrice});  
  };

  let handleChangeWall = (selectedOption) => {
    setStateWall(
      selectedOption
    );
    onUpdateFilters({ foundation: stateFoundation, developer: stateDeveloper, wall: selectedOption, regions: stateRegions, area: stateArea, price: statePrice });  
  };

  let handleChangeRegion = (selectedOption) => {
    setStateRegions(
      selectedOption
    )
    onUpdateFilters({ foundation: stateFoundation, developer: stateDeveloper, wall: stateWall, regions: selectedOption, area: stateArea, price: statePrice});  
  };

  let handleChangeTerm = (selectedOption) => {
    setStateTerm(
      selectedOption
    )
    onUpdateFilters({ foundation: stateFoundation, developer: stateDeveloper, wall: stateWall, regions: stateRegions, area: stateArea, price: statePrice, term: selectedOption});  
  };

  let selectStyle = {
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      background: '#F3F4F8',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      flexWrap: "no-wrap",
    }),
  };

  let developerOptions;
  let wallOptions;

  if(filterValues.developers != undefined && filterValues.walls != undefined) {
    developerOptions = [{ value: 0, label: "Все подрядчики" }, ...filterValues.developers];
    wallOptions = [{ value: 0, label: "Все типы стен" }, ...filterValues.walls];
  }

  return (
    <div className={styles.filters + " " + (isVisible && styles.filters_show) }>
      <div className={styles.container}>
      <Select
        styles={selectStyle}
        defaultValue={{ label: "Все подрядчики", value: 0 }}
        options={developerOptions}
        onChange={handleChangeDeveloper}
        placeholder={<div>Застройщик</div>}
      />
      {/* <Select
        styles={selectStyle}
        defaultValue={{ label: "Все типы фундамента", value: 0 }}
        options={fondationOptions}
        onChange={handleChangeFoundation}
        placeholder={<div>Фундамент</div>}
      /> */}
      <Select
        styles={selectStyle}
        options={regionOptions}
        onChange={handleChangeRegion}
        isMulti = 'true'
        placeholder={<div>Все регионы</div>}
      />
      
      <Select
        styles={selectStyle}
        defaultValue={{ label: "Все типы стен", value: 0 }}
        options={wallOptions}
        onChange={handleChangeWall}
        placeholder={<div>Тип стен</div>}
      />
      <Select
        styles={selectStyle}
        defaultValue={{ label: "Любой срок постройки", value: 0 }}
        options={termOptions}
        onChange={handleChangeTerm}
        placeholder={<div>Срок возведения</div>}
      />
      <RangeInput label="Стоимость объекта, млн" name="price" onChange={onChangePrice} type="million">
      </RangeInput>
      <RangeInput label="Площадь объекта, м²" name="area" onChange={onChangeArea} >
      </RangeInput>
      </div>
      
    </div>
  );
}

export default Filters;
