import React from 'react';
import MenuItem from './MenuItem';
import styles from './menu.module.scss';
import MobileMenu from './mobileMenu/MobileMenu';

function Menu(pops) {

  const menuItems = {
    home: {
      name: "Главная",
      url: "https://partner.ipoteka.center/",
      img: "home.svg"
    },
    calc: {
      name: "Калькулятор",
      url: "https://partner.ipoteka.center/calc",
      img: "calculator.svg"
    },
    requests: {
      name: "Заявки",
      url: "https://partner.ipoteka.center/requests",
      img: "requests.svg"
    },
    buildings: {
      name: "Новостройки",
      url: "https://partner.ipoteka.center/newflats",
      img: "buildings.svg",
    },
    overseas: {
      name: "ИЖС",
      url: "https://dom.ipoteka.center/",
      img: "houseIZHS.svg",
      activity: true
    },
    study: {
      name: "Обучение",
      url: "https://partner.ipoteka.center/training",
      img: "study.svg"
    },
    services: {
      name: "Сервисы",
      url: "https://partner.ipoteka.center/services",
      img: "services.svg"
    },
    table: {
      name: "Таблица всевластия",
      url: "https://partner.ipoteka.center/detailedconditions",
      img: "table.svg"
    },
    question: {
      name: "Помощь",
      url: "https://partner.ipoteka.center/",
      img: "question.svg"
    },
    profile: {
      name: "Профиль",
      url: "https://partner.ipoteka.center/profile",
      img: "profile.svg"
    },
    bonus: {
      name: "Показать бонус",
      url: "https://partner.ipoteka.center/",
      img: "hide bonus.svg"
    }
  };

  const desktopMenu = {
    top: [
      menuItems.home, menuItems.calc, menuItems.requests, menuItems.buildings, menuItems.overseas, menuItems.study, menuItems.services
    ],
    bottom: [
      menuItems.table, menuItems.question, menuItems.profile, menuItems.bonus
    ]
  };

  const mobileMenu = [
    [
      menuItems.home, menuItems.calc, menuItems.requests, menuItems.buildings, menuItems.overseas, menuItems.study, menuItems.services
    ],
    [
      menuItems.table
    ],
    [
      menuItems.question, menuItems.profile
    ]
  ];


  return (
    <div className="menu">
      <MobileMenu items={mobileMenu} />
      <div className={styles.mainMenu}>
        <div className={styles.mainMenu__top}>
        <img className={styles.logo} src="/img/menuIcons/sign.svg" alt="" />
          { desktopMenu.top.map((item, index) => <MenuItem type="top" item={item} key={index}/> )}
        </div>
        <div className={styles.mainMenu__bottom} >
          { desktopMenu.bottom.map((item, index) => <MenuItem type="bottom" item={item} key={index}/> )}
        </div>
      </div>
    </div>
    
  );
}

export default Menu;
